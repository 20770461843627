$env: production;
@import '@/styles/_component-base.styles.scss';

.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: normal;
    gap: spacer(md);
    justify-content: space-between;

    // @include breakpoint(md) {
        // gap: spacer(3xs);
    // }

    // High level of specificity required to override the FormCheckbox styles in the bit component
    // Once the FormCheckbox styles are refactored we can remove this hierarchy
    div {
        flex: 0 0 calc(50% - #{spacer(md)});

        @include breakpoint(md) {
            flex: 0 0 calc(33.333% - #{spacer(md)});
            width: auto;
            margin-bottom: spacer(2xs);
        }

        // this high level of specificity is required to override the FormCheckbox styles
        input[type=checkbox]:focus + label::before {
            // background-color: #c19678;
            border: 2px solid #c19678;
        }

        input[type=checkbox]:checked + label::before {
            background-color: #c19678;
        }

        input[type=checkbox]:checked + label::after {
            top: 10.4rem;
            width: 15px;
            height: spacer(xs);
            transform: scale(1) rotate(-55deg);
            left: 3px;
        }

        label {
            align-items: flex-start;
            flex-direction: column-reverse;
            padding-left: 0px;

            &::before {
                top: 163px;
                height: 20px;
                width: 20px;
            }

            span {
                margin-bottom: spacer(2xs);
                margin-left: spacer(xl);
            }
        }
    }

}

